var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// src/pages/VerifyEmail.tsx
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { userPool } from '@utils/cognito';
import { logger } from '@utils/logger';
var VerifyEmail = function () {
    var _a;
    var _b = useState(''), verificationCode = _b[0], setVerificationCode = _b[1];
    var _c = useState(null), error = _c[0], setError = _c[1];
    var location = useLocation();
    var navigate = useNavigate();
    var email = (_a = location.state) === null || _a === void 0 ? void 0 : _a.email;
    var handleSubmit = function (e) {
        e.preventDefault();
        setError(null);
        var userData = {
            Username: email,
            Pool: userPool
        };
        var cognitoUser = new CognitoUser(userData);
        cognitoUser.confirmRegistration(verificationCode, true, function (err, result) {
            if (err) {
                setError(err.message || JSON.stringify(err));
                return;
            }
            logger.log('Verification successful', result);
            navigate('/login');
        });
    };
    return (_jsxs("div", __assign({ className: "verify-email-container" }, { children: [_jsx("h2", { children: "Verify Your Email" }), _jsx("p", { children: "Please check your email for a verification code and enter it below." }), error && _jsx("div", __assign({ className: "error" }, { children: error })), _jsxs("form", __assign({ onSubmit: handleSubmit }, { children: [_jsxs("div", { children: [_jsx("label", __assign({ htmlFor: "verificationCode" }, { children: "Verification Code:" })), _jsx("input", { type: "text", id: "verificationCode", value: verificationCode, onChange: function (e) { return setVerificationCode(e.target.value); }, required: true })] }), _jsx("button", __assign({ type: "submit" }, { children: "Verify" }))] }))] })));
};
export default VerifyEmail;
