// src/utils/logger.ts
var currentStage = process.env.REACT_APP_STAGE || 'beta';
var loggingStages = ['beta', 'gamma'];
export var logger = {
    log: function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        if (loggingStages.includes(currentStage)) {
            console.log.apply(console, args);
        }
    },
    warn: function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        if (loggingStages.includes(currentStage)) {
            console.warn.apply(console, args);
        }
    },
    error: function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        // Always log errors, even in production
        console.error.apply(console, args);
    },
    info: function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        if (loggingStages.includes(currentStage)) {
            console.info.apply(console, args);
        }
    },
    debug: function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        if (loggingStages.includes(currentStage)) {
            console.debug.apply(console, args);
        }
    }
};
