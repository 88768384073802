var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// src/pages/SignUp.tsx
import { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { userPool, getPoolConfig } from '@utils/cognito';
import { validatePassword, getPasswordErrorMessage } from '@utils/passwordUtils';
import { logger } from '@utils/logger';
var SignUp = function () {
    var _a = useState(''), email = _a[0], setEmail = _a[1];
    var _b = useState(''), password = _b[0], setPassword = _b[1];
    var _c = useState(''), confirmPassword = _c[0], setConfirmPassword = _c[1];
    var _d = useState(null), error = _d[0], setError = _d[1];
    var navigate = useNavigate();
    useEffect(function () {
        var poolConfig = getPoolConfig();
        if (!poolConfig.enableSignups) {
            navigate('/login');
        }
    }, [navigate]);
    var validateEmail = function (email) {
        var re = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        var isValid = re.test(email);
        logger.log("Validating email: ".concat(email, ", isValid: ").concat(isValid));
        return isValid;
    };
    var handleSubmit = function (e) {
        e.preventDefault();
        setError(null);
        // Email validation
        if (!validateEmail(email)) {
            setError("Please enter a valid email address.");
            return;
        }
        if (!validatePassword(password)) {
            setError(getPasswordErrorMessage());
            return;
        }
        // Password matching validation
        if (password !== confirmPassword) {
            setError("Passwords do not match");
            return;
        }
        var attributeList = [
            new CognitoUserAttribute({
                Name: 'email',
                Value: email
            }),
            new CognitoUserAttribute({
                Name: 'given_name',
                Value: 'routy'
            }),
            new CognitoUserAttribute({
                Name: 'family_name',
                Value: 'User'
            })
        ];
        userPool.signUp(email, password, attributeList, [], function (err, result) {
            if (err) {
                console.error(err);
                setError(err.message || JSON.stringify(err));
                return;
            }
            logger.log('user name is ' + (result === null || result === void 0 ? void 0 : result.user.getUsername()));
            // Redirect to verification page or show a message
            navigate('/verify-email', { state: { email: email } });
        });
    };
    // If signups are not enabled, this component will redirect before rendering
    return (_jsxs("div", __assign({ className: "signup-container" }, { children: [_jsx("h2", { children: "Sign Up" }), error && _jsx("div", __assign({ className: "error" }, { children: error })), _jsxs("form", __assign({ onSubmit: handleSubmit }, { children: [_jsxs("div", { children: [_jsx("label", __assign({ htmlFor: "email" }, { children: "Email:" })), _jsx("input", { type: "email", id: "email", value: email, onChange: function (e) { return setEmail(e.target.value); }, required: true })] }), _jsxs("div", { children: [_jsx("label", __assign({ htmlFor: "password" }, { children: "Password:" })), _jsx("input", { type: "password", id: "password", value: password, onChange: function (e) { return setPassword(e.target.value); }, required: true })] }), _jsxs("div", { children: [_jsx("label", __assign({ htmlFor: "confirmPassword" }, { children: "Confirm Password:" })), _jsx("input", { type: "password", id: "confirmPassword", value: confirmPassword, onChange: function (e) { return setConfirmPassword(e.target.value); }, required: true })] }), _jsx("button", __assign({ type: "submit" }, { children: "Sign Up" }))] })), _jsxs("p", { children: ["Already have an account? ", _jsx(Link, __assign({ to: "/login" }, { children: "Log in" }))] })] })));
};
export default SignUp;
