import { jsx as _jsx } from "react/jsx-runtime";
// website/src/index.tsx
import React from 'react';
import ReactDOM from 'react-dom/client';
import 'process';
import App from './App';
import { logger } from '@utils/logger';
import { isDevServer } from '@utils/isDevServer';
var root = ReactDOM.createRoot(document.getElementById('root'));
function renderApp() {
    root.render(_jsx(React.StrictMode, { children: _jsx(App, { isDevServer: isDevServer() }) }));
}
if (isDevServer()) {
    logger.log('index.tsx - loading dev environment');
}
renderApp();
